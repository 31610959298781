import { useState, useEffect } from 'react'

interface IWindowDimensions {
  windowWidth: number
  windowHeight: number
  xl: number
  lg: number
  md: number
  sm: number
  xs: number
  xxs: number
}

function getWindowDimensions(): IWindowDimensions {
  const { innerWidth: windowWidth, innerHeight: windowHeight } = window

  const xl = process.env.REACT_APP_WINDOW_SIZE_XL
  const lg = process.env.REACT_APP_WINDOW_SIZE_LG
  const md = process.env.REACT_APP_WINDOW_SIZE_MD
  const sm = process.env.REACT_APP_WINDOW_SIZE_SM
  const xs = process.env.REACT_APP_WINDOW_SIZE_XS
  const xxs = process.env.REACT_APP_WINDOW_SIZE_XXS

  return {
    windowWidth,
    windowHeight,
    xl: Number(xl),
    lg: Number(lg),
    md: Number(md),
    sm: Number(sm),
    xs: Number(xs),
    xxs: Number(xxs),
  }
}

export default function windowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  let timer: ReturnType<typeof setTimeout>

  useEffect(() => {
    function handleResize() {
      clearTimeout(timer)
      timer = setTimeout(() => setWindowDimensions(getWindowDimensions()), 33)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}
