import React, { FC } from 'react'
import './Container.scss'

interface IProps {
  children: React.ReactNode
  className?: string
}

const Container: FC<IProps> = (props) => {
  return (
    <div className={`container ${props.className ? props.className : ''}`}>
      {props.children}
    </div>
  )
}

export default Container
