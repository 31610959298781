import React, {FC, useEffect} from 'react'
import {Helmet} from 'react-helmet'
import {BaseBorderMobile, Container} from '../../components'
import ClubMobileTop from '../../components/Club/ClubMobile/ClubMobileTop'
import ClubMobileList from '../../components/Club/ClubMobile/ClubMobileList'
import './ClubScheduleMobile.scss'
import {useAppDispatch, useAppSelector} from '../../hooks/redux'
import {useParams} from 'react-router-dom'
import {RootState} from '../../store'
import {fetchClub} from '../../store/slices/club'
import {useTranslation} from 'react-i18next'
import TickerReact from '../../components/TickerReact/TickerReact'

const ClubSchedulePage: FC = () => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const {id} = useParams()

    const {club} = useAppSelector((state: RootState) => state.club)

    useEffect(() => {
        dispatch(fetchClub(id))
    }, [])

    return (
        <>
            <Helmet>
                {club?.seo?.title && <title>{club.seo.title}</title>}
                {club?.seo?.description && (
                    <meta name="description" content={`${club?.seo?.description}`}/>
                )}
            </Helmet>
            <div className="schedule-mobile">
                <BaseBorderMobile/>
                <Container>
                    {club && (
                        <>
                            <ClubMobileTop>
                                <span>{t('timetable')}</span>pulse gym {club?.name}
                            </ClubMobileTop>
                            <div className="schedule-mobile__wrapper">
                                {Object.keys(club.schedule).map((item, index) => (
                                    <ClubMobileList
                                        key={index}
                                        title={club.schedule[item].service}
                                        schedule={club.schedule[item]}
                                    />
                                ))}
                            </div>
                        </>
                    )}
                </Container>
                {/*<ClubSportBar image={club?.image} />*/}
            </div>
            <TickerReact className="_mb-2"/>
        </>
    )
}

export default ClubSchedulePage
