import React from 'react'

interface IBaseSvg {
  name: string
  className?: string
}

const BaseSvg = ({ name, className }: IBaseSvg) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className}>
      <use xlinkHref={`#${name}`} />
    </svg>
  )
}

export default BaseSvg
