import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import Promo from '../../components/Promo/Promo'
import { BaseSvg, Container, Map } from '../../components'
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import { IBreadCrumbs } from '../../models/BreadCrumbs'
import '../../assets/scss/_utils.scss'
import './Contacts.scss'
import TickerReact from '../../components/TickerReact/TickerReact'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { RootState } from '../../store'
import { fetchClubs } from '../../store/slices/clubs'
import { IClubs } from '../../models/Clubs'
import AppLink from '../../components/Base/AppLink'
import { useTranslation } from 'react-i18next'
import moment from 'moment/moment'

const Index = () => {
  const now = moment()
  const { t } = useTranslation()
  const [breadCrumbs] = useState<IBreadCrumbs[]>([
    {
      id: 1,
      name: t('contacts'),
    },
  ])
  const dispatch = useAppDispatch()
  const { clubs } = useAppSelector((state: RootState) => state.clubs)

  useEffect(() => {
    if (clubs && clubs.length) return
    dispatch(fetchClubs())
  }, [])

  return (
    <>
      <Helmet>
        <title>{t('contacts_pulse_gym')}</title>
        <meta name="description" content={`${t('contacts_network_of_gyms')}`} />
      </Helmet>
      <div className="contacts">
        <Promo title={t('contacts')}>
          <AppLink
            to="/clubs?tab=map"
            className="contacts__btn btn _strong-italic"
          >
            <span className="d-md-none">{t('see_all_clubs_on_the_map')}</span>
            <BaseSvg name="ico-arrow-right-long" className="d-md-none" />

            <span className="d-none d-md-inline-flex">{t('on_the_map')}</span>
            <BaseSvg
              name="ico-map"
              className="contacts__btn-svg d-none d-md-inline-flex"
            />
          </AppLink>
        </Promo>
        <BreadCrumbs dataList={breadCrumbs} />
        <Container>
          <ul className="contacts__list">
            {clubs.length
              ? clubs.map((item: IClubs) =>
                  moment(item.created_at) < now ? (
                    <li key={item.id} className="contacts__item">
                      <div className="contacts__item-content">
                        <AppLink
                          to={`/club/${item.slug}/`}
                          className="contacts__item-title"
                        >
                          <span>pulse gym {item.name}</span>
                          <BaseSvg
                            name="ico-arrow-right-short"
                            className="contacts__item-title-svg"
                          />
                        </AppLink>
                        <ul className="contacts__item-items">
                          <li className="contacts__item-item">
                            <span className="contacts__item-label">
                              {t('the_address')}
                            </span>
                            <span className="contacts__item-value">
                              {item.address}
                            </span>
                          </li>
                          <li className="contacts__item-item">
                            <span className="contacts__item-label">
                              {t('working_mode')}
                            </span>
                            <span className="contacts__item-value">
                              {item.scheduler}
                            </span>
                          </li>
                          <li className="contacts__item-item">
                            <span className="contacts__item-label">
                              {t('phones')}
                            </span>
                            <span className="contacts__item-value">
                              {item?.phone.length
                                ? item?.phone.join(', ')
                                : null}
                            </span>
                          </li>
                        </ul>
                      </div>

                      <Map
                        hideInfoWindow
                        markers={[
                          {
                            id: item.id,
                            title: item.name,
                            address: item.address,
                            slug: item.slug,
                            position: {
                              lat: Number(item.lat),
                              lng: Number(item.lng),
                            },
                          },
                        ]}
                        className="contacts__item-map _small-fullscreen-btn"
                      />
                    </li>
                  ) : null
                )
              : null}
          </ul>
        </Container>
        <TickerReact className="_mb-2" />
      </div>
    </>
  )
}

export default Index
