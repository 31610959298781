import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '../../api'
import { ITrainer } from '../../models/Trainer'

export const fetchTrainer = createAsyncThunk(
  'trainer/fetchTrainer:load',
  async function ({ id, slug }: { id: string; slug: string }) {
    if (!id || !slug) return
    const response = await api.get(`clubs/${id}/coach/${slug}`)
    return response.data
  }
)

interface trainerState {
  trainer: ITrainer | null
}

const initialState = {
  trainer: null,
} as trainerState

const trainerSLice = createSlice({
  name: 'trainer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTrainer.fulfilled, (state, action) => {
      state.trainer = action.payload.data
    })
  },
})

export const trainerReducers = trainerSLice.reducer
