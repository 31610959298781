import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '../../api'
import { IPromo } from '../../models/Promo'

export const fetchPromo = createAsyncThunk(
  'promo/fetchPromo:load',
  async function () {
    const response = await api.get(`/promo`)
    return response.data
  }
)

interface IPromoState {
  promo: IPromo | null
}

const initialState = {
  promo: null,
} as IPromoState

const blogsSlice = createSlice({
  name: 'promo',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPromo.fulfilled, (state, action) => {
      state.promo = action.payload.data as IPromo
    })
  },
})

export const promoReducers = blogsSlice.reducer
