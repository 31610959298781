import React, { FC, useEffect, useState, memo } from 'react'
import { Helmet } from 'react-helmet'
import ReactHtmlParser from 'react-html-parser'
import { Swiper, SwiperSlide } from 'swiper/react'
import './RehabilitationCenter.scss'
import {
  BaseBorderMobile,
  BaseSliderPagination,
  ClubListTitle,
  ClubSlider,
  ClubTop,
  Container,
  Ticker,
  Trainer,
} from '../../components'
import Promo from '../../components/Promo/Promo'
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import { IBreadCrumbs } from '../../models/BreadCrumbs'
import { useTranslation } from 'react-i18next'
import { ITrainer } from '../../models/Trainer'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { RootState } from '../../store'
import { fetchSmartFit } from '../../store/slices/smartFit'
import SwiperCore, { Navigation, EffectFade } from 'swiper'

SwiperCore.use([Navigation, EffectFade])

const RehabilitationCenter: FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { smartFit } = useAppSelector((state: RootState) => state.smartFit)
  const [breadCrumbs, setBreadCrumbs] = useState<IBreadCrumbs[]>([
    {
      id: 1,
      name: t('our_clubs'),
      link: '/clubs',
    },
  ])

  useEffect(() => {
    if (!smartFit) return
    setBreadCrumbs((prevState: IBreadCrumbs[]) => [
      prevState[0],
      { id: 2, name: `PULSE GYM ${smartFit.name}` },
    ])
  }, [smartFit])

  useEffect(() => {
    if (smartFit) return
    dispatch(fetchSmartFit())
  }, [])

  return (
    <>
      <Helmet>
        <title>{t('smart_fit_on_Saltovka_pulse_gym')}</title>
        <meta
          name="description"
          content={`${t('smart_fit_on_Saltovka_network_of_gyms')}`}
        />
      </Helmet>
      <div className="rehabilitation-center">
        <BaseBorderMobile />
        <ClubSlider
          media={smartFit?.media || []}
          className="d-none d-md-block"
        />
        <div className="rehabilitation-center__advantages d-none d-md-block">
          <Container>
            <div className="club-page__title">SMART FIT {smartFit?.name}</div>
            <ClubListTitle />
          </Container>
        </div>
        <Promo title={smartFit?.name || ''} className="d-md-none">
          <ClubListTitle />
        </Promo>
        <BreadCrumbs dataList={breadCrumbs} />
        <Container>
          <div className="rehabilitation-center__info">
            <div className="rehabilitation-center__info-title">
              {smartFit?.top_title}
            </div>
            <div className="rehabilitation-center__info-row">
              <div className="rehabilitation-center__info-text">
                {ReactHtmlParser(smartFit?.text)}
              </div>
              <picture className="rehabilitation-center__info-picture d-md-none">
                <img
                  src={smartFit?.image}
                  alt={smartFit?.title}
                  className="rehabilitation-center__info-img"
                />
              </picture>
            </div>
          </div>
        </Container>
        <Container>
          <div className="rehabilitation-center__map-title d-md-none">
            {t('rehabilitologists')}
          </div>
        </Container>
        {smartFit?.coaches?.length ? (
          <Swiper
            slidesPerView={'auto'}
            pagination={{
              el: '.rehabilitation-center__carousel-pagination',
              clickable: true,
            }}
            effect={'fade'}
            className="rehabilitation-center__carousel d-none d-md-flex"
          >
            {smartFit.coaches.map((item: ITrainer) => (
              <SwiperSlide
                key={item.id}
                className="rehabilitation-center__carousel-item"
              >
                <Trainer
                  key={item.id}
                  position={`${t('rehabilitologists')}`}
                  isNameTitle
                  trainer={item}
                  className="rehabilitation-center__trainer"
                />
              </SwiperSlide>
            ))}
            <BaseSliderPagination className="rehabilitation-center__carousel-pagination d-flex" />
          </Swiper>
        ) : null}
        {smartFit?.coaches?.length
          ? smartFit.coaches.map((item: ITrainer) => (
              <Trainer
                key={item.id}
                isNameTitle
                trainer={item}
                className="rehabilitation-center__trainer d-md-none"
              />
            ))
          : null}
        <Container>
          <div className="rehabilitation-center__map-title">
            {t('contacts_of_the_center')}
          </div>
        </Container>
        {smartFit?.lat && smartFit?.lng && (
          <ClubTop
            isLinkToMaps
            address={smartFit?.address || ''}
            slug={smartFit.slug || ''}
            lat={Number(smartFit?.lat) || 0}
            lng={Number(smartFit?.lng) || 0}
            phone={smartFit?.phone || []}
            name={smartFit?.name || ''}
            scheduler={smartFit?.scheduler || ''}
          />
        )}
        <ClubSlider
          media={smartFit?.media || []}
          className="rehabilitation-center__slider d-md-none"
        />
        <Ticker className="_mb-2 d-md-none" />
      </div>
    </>
  )
}

export default memo(RehabilitationCenter)
