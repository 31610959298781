import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '../../api'
import { ITags } from '../../models/Tags'

export const fetchTags = createAsyncThunk(
  'feedback/fetchFeedback:load',
  async function () {
    const response = await api.get('tags')
    return response.data
  }
)

interface ITagsState {
  tags: ITags[]
}

const initialState = {
  tags: [],
} as ITagsState

const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTags.fulfilled, (state, action) => {
      state.tags = action.payload.data
    })
  },
})

export const tagsReducers = tagsSlice.reducer
