import React, { FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import Promo from '../../components/Promo/Promo'
import { BaseSvg, Container } from '../../components'
import './Photo.scss'
import TickerReact from '../../components/TickerReact/TickerReact'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { RootState } from '../../store'
import { fetchClubs } from '../../store/slices/clubs'
import { IClubs } from '../../models/Clubs'
import AppLink from '../../components/Base/AppLink'
import { useTranslation } from 'react-i18next'
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import { IBreadCrumbs } from '../../models/BreadCrumbs'
import moment from 'moment/moment'

const Photo: FC = () => {
  const { t } = useTranslation()
  const now = moment()
  const dispatch = useAppDispatch()
  const { clubs } = useAppSelector((state: RootState) => state.clubs)

  const [breadCrumbs] = useState<IBreadCrumbs[]>([
    {
      id: 1,
      name: t('photo_gallery'),
    },
  ])

  useEffect(() => {
    if (clubs && clubs.length) return
    dispatch(fetchClubs())
  }, [])

  return (
    <>
      <Helmet>
        <title>{t('photo_gallery_pulse_gym')}</title>
        <meta
          name="description"
          content={`${t('pulse_gym_photos_of_our_clubs')}`}
        />
      </Helmet>
      <div className="photo">
        <Promo title={t('photo_gallery')}>
          <div className="photo__title d-md-none">
            {t('all_clubs_of_the_pulse_gym_network')}
          </div>
        </Promo>
        <BreadCrumbs dataList={breadCrumbs} />
        <Container>
          <ul className="photo__list">
            {clubs &&
              clubs.map((item: IClubs) =>
                moment(item?.created_at) < now ? (
                  <li key={item.id} className="photo__item">
                    <AppLink
                      to={`/gallery/${item.slug}/`}
                      className="photo__item-wrap"
                    >
                      <div className="photo__item-top">
                        <div className="photo__item-content">
                          <picture className="photo__item-picture">
                            <img
                              src={item.image}
                              alt="img"
                              className="photo__item-img"
                            />
                          </picture>
                          <div className="photo__item-info">
                            <div className="photo__item-name">Pulse Gym</div>
                            <div className="photo__item-description">
                              {item.name}
                            </div>
                          </div>
                        </div>
                        <div className="photo__item-street">{item.address}</div>
                      </div>
                      <div className="photo__item-btn">
                        <span> {t('see_photo')}</span>
                        <BaseSvg
                          name="ico-arrow-right"
                          className="photo__item-btn-svg"
                        />
                      </div>
                    </AppLink>
                  </li>
                ) : null
              )}
          </ul>
        </Container>
        <TickerReact className="photo__ticker _mb-2" />
      </div>
    </>
  )
}

export default Photo
