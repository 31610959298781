import { uniqueId } from 'lodash'

export interface ILanguages {
  defaultLanguageCode: string
  languages: IListLanguages[]
}

export interface IListLanguages {
  id: string
  name: string
  locale: string
  show_code: string
  country_code: string
}

export const languages: ILanguages = {
  defaultLanguageCode: (window as any)?.lang_default ?? 'ua',
  languages: [
    {
      id: uniqueId(),
      name: 'Українська мова',
      locale: 'uk',
      show_code: 'ua',
      country_code: 'UA',
    },
    {
      id: uniqueId(),
      name: 'Русский язык',
      locale: 'ru',
      show_code: 'ru',
      country_code: 'RU',
    },
  ],
}
