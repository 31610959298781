import React, { FC, Fragment, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { BaseBorderMobile, Container } from '../../components'
import ClubMobileTop from '../../components/Club/ClubMobile/ClubMobileTop'
import ClubMobileList from '../../components/Club/ClubMobile/ClubMobileList'
import './ClubPriceMobile.scss'
import { useParams } from 'react-router-dom'
import { fetchClub } from '../../store/slices/club'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { RootState } from '../../store'
import { IPrice } from '../../models/Clubs'
import { useTranslation } from 'react-i18next'
import TickerReact from '../../components/TickerReact/TickerReact'

const ClubPrice: FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { id } = useParams()

  const { club } = useAppSelector((state: RootState) => state.club)

  useEffect(() => {
    dispatch(fetchClub(id))
  }, [])

  return (
    <>
      <Helmet>
        {club?.seo?.title && <title>{club.seo.title}</title>}
        {club?.seo?.description && (
          <meta name="description" content={`${club?.seo?.description}`} />
        )}
      </Helmet>
      <div className="price-mobile">
        <BaseBorderMobile />
        <Container>
          <ClubMobileTop>
            <span>{t('price_list')}</span>
            pulse gym {club?.name && club.name}
          </ClubMobileTop>
          <div className="price-mobile__wrapper">
            {club?.price &&
              club.price.map((item: IPrice, index: number) => (
                <Fragment key={`${item.id}_${index}`}>
                  <ClubMobileList
                    title={item.name}
                    baner={item.baner}
                    price={item.price}
                  />
                </Fragment>
              ))}
          </div>
        </Container>
      </div>
      <TickerReact className="_mb-2" />
    </>
  )
}

export default ClubPrice
