import React, { FC } from 'react'
import './ButtonSlider.scss'
import { BaseSvg } from '../index'

interface IProps {
  className?: React.ReactNode
  type: 'prev' | 'next'
  isHover?: boolean
}

const ButtonSlider: FC<IProps> = (props) => {
  return (
    <button
      className={`btn-slider ${props.className} _${props.type} ${
        props.isHover ? '_hover' : ''
      }`}
    >
      <BaseSvg name="ico-arrow-right" />
    </button>
  )
}

ButtonSlider.defaultProps = {
  className: '',
  isHover: false,
}

export default ButtonSlider
