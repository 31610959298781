import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '../../api'
import { IBlogs } from '../../models/Blogs'

export const fetchBlog = createAsyncThunk(
  'blog/fetchBlog:load',
  async function (id: number | string | undefined, { rejectWithValue }) {
    try {
      const response = await api.get(`blogs/${id}`)
      return response.data
    } catch (error) {
      throw rejectWithValue(error)
    }
  }
)

interface IBlogState {
  blog: IBlogs | null
  errors: any
}

const initialState = {
  blog: null,
  errors: null,
} as IBlogState

const blogsSlice = createSlice({
  name: 'blog',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBlog.fulfilled, (state, action) => {
      state.blog = action.payload.data as IBlogs
    })
    builder.addCase(fetchBlog.rejected, (state, action) => {
      state.errors = action.payload
    })
  },
})

export const blogReducers = blogsSlice.reducer
