import React, { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import './WriteFeedback.scss'
import '../../assets/scss/_utils.scss'
import BaseSelect from '../Base/BaseSelect/BaseSelect'
import BaseInput from '../Base/BaseInput/BaseInput'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { RootState } from '../../store'
import { fetchClubs } from '../../store/slices/clubs'
import { clearErrors, sendFeedback } from '../../store/slices/feedback'
import { useTranslation } from 'react-i18next'

interface IProps {
  close: () => void
}

const WriteFeedback: FC<IProps> = (props) => {
  const { t } = useTranslation()
  const { clubs } = useAppSelector((state: RootState) => state.clubs)

  const { errors } = useAppSelector((state: RootState) => state.feedback)

  const dispatch = useAppDispatch()

  const state = {
    name: '',
    email: '',
    feedback: '',
    club_id: '',
  }

  const { register, handleSubmit, setValue } = useForm<any>({
    mode: 'onSubmit',
    defaultValues: state,
  })

  const onSubmit = (data: any) => {
    dispatch(sendFeedback(data))
      .unwrap()
      .then(() => {
        props.close()
      })
  }

  const callBack = (val: number) => {
    setValue('club_id', val)
  }

  useEffect(() => {
    dispatch(fetchClubs())
    return (): any => dispatch(clearErrors())
  }, [])

  return (
    <div className="write-feedback">
      <div className="write-feedback__inner">
        <div className="write-feedback__title d-md-none">
          {t('leave_your_feedback')}
        </div>
        <div className="write-feedback__description">
          {t('dear_customers_please_clarify_in_your_reviews')}
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="write-feedback__form"
        >
          <BaseSelect
            options={clubs}
            value={state.club_id}
            name="club_id"
            register={register}
            callBack={callBack}
            errors={errors}
            className="write-feedback__select"
          />
          <BaseInput
            name="name"
            type="text"
            register={register}
            placeholder={t('your_name') || ''}
            errors={errors}
            className="write-feedback__input"
          />
          <BaseInput
            name="email"
            register={register}
            type="email"
            placeholder={t('your_email') || ''}
            errors={errors}
            className="write-feedback__input"
          />
          <BaseInput
            textarea
            name="feedback"
            register={register}
            errors={errors}
            placeholder={t('feedback_text') || ''}
            className="write-feedback__input"
          />
          <button type="submit" className="write-feedback__btn btn _orange">
            {t('publish')}
          </button>
        </form>
      </div>
    </div>
  )
}

export default WriteFeedback
