import React from 'react'
import './ButtonArrowRight.scss'
import { BaseSvg } from '../index'
import AppLink from '../Base/AppLink'

interface IProps {
  children: React.ReactNode
  className?: React.ReactNode
  to?: string
  isModMobile?: boolean
  color?: '_orange' | '_green'
  onClick?: () => void
}

const ButtonArrowRight = ({
  children,
  className,
  to,
  color = '_orange',
  isModMobile,
  onClick,
}: IProps) => {
  return (
    <>
      {to && (
        <AppLink
          onClick={onClick && onClick}
          className={`button-arrow-right ${color} ${
            className ? className : ''
          } ${isModMobile ? '_mod' : ''}`}
          to={to}
        >
          {children}
          <BaseSvg name="ico-arrow-right" />
        </AppLink>
      )}
      {!to && (
        <button
          onClick={onClick && onClick}
          className={`button-arrow-right ${color} ${
            className ? className : ''
          } ${isModMobile ? '_mod' : ''}`}
        >
          {children}
          <BaseSvg name="ico-arrow-right" />
        </button>
      )}
    </>
  )
}

export default ButtonArrowRight
