import React, { FC } from 'react'
import { Button } from '../../index'
import './ClubPriceCoachServices.scss'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import windowDimensions from '../../../hooks/windowDimensions'

interface IProps {
  slug?: string
  title: string
  sub_title: string
  mobile_title: string
  mobile_sub_title: string
}

const ClubPriceCoachServices: FC<IProps> = (props) => {
  const { windowWidth, md } = windowDimensions()
  const { id } = useParams()
  const { i18n, t } = useTranslation()

  return (
    <div className="coach-services">
      <div className="coach-services__title">
        {props.title}

        {props.slug ? (
          <p> {props.sub_title}</p>
        ) : (
          <span> {props.sub_title}</span>
        )}
      </div>
      {props.slug ? (
        <Button
          to={`/trainer/${id}/${props.slug}`}
          className={`coach-services__btn ${windowWidth < md ? '_green' : ''}`}
        >
          personal coach
        </Button>
      ) : (
        <Button
          to={
            i18n.language === 'ru'
              ? '/regulations/pravyla-poseshhenyya-sportyvnogo-kluba-tehnyka-bezopasnosty#freeze'
              : '/regulations/pravyla-vidviduvannya-sportyvnogo-klubu-tehnika-bezpeky#freeze'
          }
          className="coach-services__btn"
        >
          {t('learn_more')}
        </Button>
      )}
    </div>
  )
}

export default ClubPriceCoachServices
