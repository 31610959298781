import React, { FC } from 'react'
import '../../pages/Reviews/Reviews.scss'
import { BaseSvg } from '../index'
import moment from 'moment/moment'
import { useTranslation } from 'react-i18next'

interface IPros {
  feedback: string
  date: string
  name: string
  address: string
  answer?: null | string
}

const ReviewItem: FC<IPros> = (props) => {
  const { t } = useTranslation()

  return (
    <li className="reviews__item">
      <div className="reviews__item-top">
        <BaseSvg name="ico-quotes" className="reviews__item-quotes" />
        <blockquote className="reviews__item-info">
          <span className="reviews__item-info-name">
            {props.name}
            <span className="d-md-none">,</span>
          </span>
          <span className="reviews__item-info-name-street d-md-none">
            {props.address}
          </span>
        </blockquote>
        <div className="reviews__item-date d-md-none">
          <BaseSvg name="ico-time" className="reviews__item-date-svg" />
          <time dateTime={props.date}>
            {moment(props.date).format('DD.MM.YYYY')}
          </time>
        </div>
      </div>
      <div className="reviews__item-mobile-street d-none d-md-block">
        {props.address}
      </div>
      <div className="reviews__item-main">"{props.feedback}"</div>
      <div className="reviews__item-date d-none d-md-flex">
        <BaseSvg name="ico-time" className="reviews__item-date-svg" />
        <time dateTime={moment().format()}>
          {moment().format('DD.MM.YYYY')}
        </time>
      </div>
      {props.answer && (
        <div className="reviews__item-answer">
          <BaseSvg
            name="ico-arrow-top-right"
            className="reviews__item-answer-svg"
          />
          <div className="reviews__item-col">
            <div className="reviews__item-name">
              {t('answer_from_pulse_gym_administration')}
            </div>
            <div className="reviews__item-text">{props.answer}</div>
          </div>
        </div>
      )}
    </li>
  )
}

export default ReviewItem
