import React, { FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import Promo from '../../components/Promo/Promo'
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import { IBreadCrumbs } from '../../models/BreadCrumbs'
import { BaseCheckbox, ClubsItems, HomeMap } from '../../components'
import './Clubs.scss'
import TickerReact from '../../components/TickerReact/TickerReact'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { fetchClubs } from '../../store/slices/clubs'
import { RootState } from '../../store'
import { IClubs } from '../../models/Clubs'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Clubs: FC = () => {
  const { t } = useTranslation()
  const [breadCrumbs] = useState<IBreadCrumbs[]>([
    {
      id: 1,
      name: t('our_clubs'),
    },
  ])
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const { clubs, polygon } = useAppSelector((state: RootState) => state.clubs)
  const [markers, setMarkers] = useState([])

  useEffect(() => {
    if (clubs && clubs.length) return
    dispatch(fetchClubs())
  }, [])

  useEffect(() => {
    if (!clubs.length) return
    const result: any = []
    clubs.forEach((el: IClubs) => {
      result.push({
        id: el.id,
        title: el.name,
        address: el.address,
        slug: el.slug,
        position: {
          lat: +el.lat,
          lng: +el.lng,
        },
      })
    })
    setMarkers(result)
  }, [clubs])

  return (
    <>
      <Helmet>
        <title>{t('our_clubs_pulse_gym')}</title>
        <meta
          name="description"
          content={`${t('our_clubs_network_of_gyms')}`}
        />
      </Helmet>
      <div className="clubs-page">
        <Promo title={t('our_clubs')}>
          <BaseCheckbox />
        </Promo>
        <BreadCrumbs dataList={breadCrumbs} />
        {clubs.length && polygon ? (
          <div className="clubs-page__wrapper">
            {searchParams.get('tab') === 'map' ? (
              <HomeMap
                hideTitle
                markers={markers}
                polygon={polygon}
                className="clubs-page__map"
              />
            ) : (
              <ClubsItems items={clubs} />
            )}
          </div>
        ) : null}
        <TickerReact className="clubs-page__ticker" />
      </div>
    </>
  )
}

export default Clubs
