import React, { useEffect, memo } from 'react'
import { BaseSvg, Button, Container } from '../../index'
import './Welcome.scss'
import img_girl from '../../../assets/image/girl.png'
import img_man from '../../../assets/image/man.png'
import welcome_gym from '../../../assets/image/welcome_gym.jpg'
import welcome_gym_m from '../../../assets/image/welcome_gym_m.jpg'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { fetchPromo } from '../../../store/slices/promo'
import { RootState } from '../../../store'
import AppLink from '../../Base/AppLink'
import { useTranslation } from 'react-i18next'

const Welcome = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { promo } = useAppSelector((state: RootState) => state.promo)

  useEffect(() => {
    if (promo) return
    dispatch(fetchPromo())
  }, [])

  return (
    <section className="welcome">
      <Container className="welcome__inner">
        <div className="welcome__body">
          <img src={img_girl} className="welcome__girl" alt="img" />
          <img src={img_man} className="welcome__man" alt="img" />
          <div className="welcome__bg welcome__bg-1" />
          <div className="welcome__bg welcome__bg-2" />
          <div className="welcome__bg welcome__bg-3" />
          {promo?.sub_title && promo?.title && (
            <h1 className="welcome__title title-large d-md-none">
              {promo?.sub_title}
              <span className="title-large__name">{promo?.title}</span>
            </h1>
          )}

          <h1 className="welcome__title title-large d-none d-md-block">
            {promo?.mobile_title && promo?.mobile_title}
          </h1>
          <div className="welcome__visitors">
            {promo?.images?.length ? (
              <div className="welcome__visitors-img">
                {promo?.images.map((el: string, index: number) => (
                  <img
                    key={index}
                    className={
                      index < promo?.images?.length - 1 ? 'd-md-none' : ''
                    }
                    src={el}
                    alt="img"
                  />
                ))}
                <div className="welcome__visitors-plus">
                  <BaseSvg name="ico-plus" />
                </div>
              </div>
            ) : null}

            <div className="welcome__visitors-info">
              {promo?.description && promo?.description}
              <div className="welcome__visitors-bottom d-md-none">
                <div className="welcome__visitors-stars">
                  <BaseSvg name="ico-star" />
                  <BaseSvg name="ico-star" />
                  <BaseSvg name="ico-star" />
                  <BaseSvg name="ico-star" />
                  <BaseSvg name="ico-star" />
                </div>
                {t('join_and_you')}
              </div>
            </div>
          </div>
          <div className="welcome__visitors-bottom d-none d-md-flex">
            <div className="welcome__visitors-stars">
              <BaseSvg name="ico-star" />
              <BaseSvg name="ico-star" />
              <BaseSvg name="ico-star" />
              <BaseSvg name="ico-star" />
              <BaseSvg name="ico-star" />
            </div>
            {t('join_and_you')}
          </div>
          <div className="welcome__buttons">
            <Button to="/clubs/" className="welcome__button-btn _contrast">
              {t('choose_a_club_2')}
            </Button>
            <AppLink className="welcome__button-photo d-md-none" to="/gallery/">
              <span className="welcome__button-svg">
                <BaseSvg name="ico-play" />
              </span>
              {t('see')}
              <br /> {t('photo_gallery')}
            </AppLink>
          </div>
        </div>
      </Container>
      <picture className="welcome__gym">
        <source
          media="(min-width: 1024px)"
          srcSet={welcome_gym}
          className="welcome__gym-img"
        />
        <img src={welcome_gym_m} alt="img" className="welcome__gym-img" />
      </picture>
    </section>
  )
}

export default memo(Welcome)
