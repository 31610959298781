
import React, { FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { BaseSvg, Container } from '../../components'
import './Reviews.scss'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import Promo from '../../components/Promo/Promo'
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import { IBreadCrumbs } from '../../models/BreadCrumbs'
import '../../assets/scss/_utils.scss'
import ReactPaginate from 'react-paginate'
import { useNavigate, useSearchParams } from 'react-router-dom'
import TickerReact from '../../components/TickerReact/TickerReact'
import Modal from 'react-modal'
import WriteFeedback from '../../components/WriteFeedback/WriteFeedback'
import useBasicStyleModal from '../../helpers/getBasicStyleModal'
import { fetchFeedback } from '../../store/slices/feedback'
import ReviewItem from '../../components/ReviewItem/ReviewItem'
import useScrollTo from '../../hooks/scrollTo'
import { useTranslation } from 'react-i18next'

const Index: FC = () => {
  const { t } = useTranslation()
  const { scrollTo } = useScrollTo()
  const { basicStyleModal } = useBasicStyleModal()
  const [modalIsOpen, setIsOpen] = useState(false)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { data } = useAppSelector((state) => state.feedback)
  const { isGlobalPending } = useAppSelector((state) => state.loading)
  const { pagination } = useAppSelector((state) => state.feedback)

  const [breadCrumbs] = useState<IBreadCrumbs[]>([
    {
      id: 1,
      name: t('reviews'),
    },
  ])

  useEffect(() => {
    const page = Number(searchParams.get('page')) || 1
    dispatch(fetchFeedback(page))
  }, [navigate, searchParams])

  const handlePageClick = (e: { selected: number }) => {
    scrollTo(e.selected + 1)
  }

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  return (
    <>
      <Helmet>
        <title>{t('reviews_pulse_gym')}</title>
        <meta
          name="description"
          content={`${t('reviews_of_a_network_of_gyms')}`}
        />
      </Helmet>
      <div className="reviews">
        <Promo title={t('reviews')}>
          <button
            type="button"
            onClick={openModal}
            className="reviews__btn btn _gold-simple _strong-italic"
          >
            <span className="d-md-none">{t('leave_your_feedback')}</span>
            <span className="d-none d-md-inline-flex">{t('write_your')}</span>
            <BaseSvg name="ico-arrow-right-long" />
          </button>
        </Promo>
        <BreadCrumbs dataList={breadCrumbs} />
        <div className="reviews__content">
          <Container>
            {data && !isGlobalPending && data?.length ? (
              <ul className="reviews__list">
                {data?.map((item: any) => (
                  <ReviewItem
                    key={item.id}
                    feedback={item.feedback}
                    date={item.created_at}
                    name={item.name}
                    address={item?.club?.address || ''}
                    answer={item.answer}
                  />
                ))}
              </ul>
            ) : (
              ''
            )}

            {pagination && pagination.last_page > 1 ? (
              <ReactPaginate
                breakLabel="..."
                pageCount={pagination?.last_page ?? 1}
                onPageChange={(e) => handlePageClick(e)}
                hrefBuilder={(page: number, pageCount: number) =>
                  page >= 1 && page <= pageCount ? `/?page=${page}` : '#'
                }
                initialPage={
                  searchParams.get('page')
                    ? Number(searchParams.get('page')) - 1
                    : 0
                }
                pageRangeDisplayed={2}
                renderOnZeroPageCount={() => null}
                marginPagesDisplayed={1}
                containerClassName="paginate"
                activeClassName="_active"
                pageClassName="paginate__item"
                pageLinkClassName="paginate__link"
                breakClassName="paginate__break"
                previousClassName="paginate__previous"
                nextClassName="paginate__next"
              />
            ) : (
              ''
            )}
          </Container>
          <TickerReact className="_mb-2 d-md-none" />
        </div>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Write a feedback"
          closeTimeoutMS={200}
          preventScroll={true}
          style={basicStyleModal}
          bodyOpenClassName="_scroll-lock"
          portalClassName="modal-portal"
        >
          <WriteFeedback close={closeModal} />
        </Modal>
      </div>
    </>
  )
}

export default Index
