import React, { FC, memo } from 'react'
import { BaseNew } from '../index'
import moment from 'moment'

interface IBadge {
  new: boolean
  not_working: boolean
  created_at: string
  className?: string
}

const Badge: FC<IBadge> = (props) => {
  const date = moment(props.created_at)
  const now = moment()

  if (props.not_working) {
    return <BaseNew isNotWorking className={props.className} />
  }

  if (date > now) {
    return <BaseNew isOpeningSoon className={props.className} />
  }

  return props.new ? <BaseNew className={props.className} /> : null
}

Badge.defaultProps = {
  className: '',
}

export default memo(Badge)
