import React, { FC, useState, InputHTMLAttributes } from 'react'
import './BaseInput.scss'
import { UseFormRegister } from 'react-hook-form'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  textarea?: boolean
  register: UseFormRegister<any>
  className?: string
  required?: boolean
  errors?: any
}

const BaseInput: FC<InputProps> = (props) => {
  const [isFocused, setIsFocused] = useState<boolean>(false)

  return (
    <>
      <label
        className={`base-input ${props.className} ${isFocused ? '_focus' : ''}`}
      >
        <span className="base-input__inner">
          {props.textarea ? (
            <textarea
              {...props.register(props.name, {
                required: !!props.required,
              })}
              placeholder={props.placeholder}
              onFocus={() => setIsFocused(true)}
              onBlur={() => {
                setIsFocused(false)
              }}
              className="base-input__input _textarea"
            />
          ) : (
            <input
              {...props.register(props.name, {
                required: !!props.required,
              })}
              placeholder={props.placeholder}
              onFocus={() => setIsFocused(true)}
              onBlur={() => {
                setIsFocused(false)
              }}
              className="base-input__input"
            />
          )}
        </span>
        {props?.errors && props?.errors[props.name] && (
          <ul className="base-input__errors">
            {props?.errors[props.name].map((el, index) => (
              <li key={index} className="base-input__errors-item">
                * {el}
              </li>
            ))}
          </ul>
        )}
      </label>
    </>
  )
}

export default BaseInput
