import axios, { AxiosRequestConfig } from 'axios/index'

const getBaseUrl = () => {
    let url;

    switch(process.env.REACT_APP_ENV) {
        case 'local':
            url = process.env.REACT_APP_BASE_URL_LOCAL;
            break;
        case 'stage':
            url = process.env.REACT_APP_BASE_URL_DEV;
            break;
        case 'production':
        default:
            url = process.env.REACT_APP_BASE_URL_PROD;
    }

    return url;
}

export const BaseAxiosConfig: AxiosRequestConfig = {
  baseURL: getBaseUrl(),
  timeout: 10 * 60 * 1000,
  headers: {
    'Content-Type': 'application/json',
    localPending: false,
  },
}

const api = axios.create(BaseAxiosConfig)

export default api
