import React, { memo } from 'react'
import Ticker from 'react-ticker'
import { BaseSvg } from '../index'
import './Ticker.scss'
import { useTranslation } from 'react-i18next'

interface IProps {
  className?: string
}

const TickerReact = ({ className = '' }: IProps) => {
  const { t } = useTranslation()

  return (
    <div className={`ticker ${className}`}>
      <Ticker speed={7}>
        {() => (
          <div className="ticker__inner">
            <BaseSvg name="ico-lightning" className="ticker__svg" />
            <span className="ticker__text">
              {t('pulse_gym_network_of_sports_clubs_in_Kharkov')}
            </span>
          </div>
        )}
      </Ticker>
    </div>
  )
}

export default memo(TickerReact)
