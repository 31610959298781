import React, { FC } from 'react'
import { Container } from '../../index'
import './ClubSportBar.scss'
import { useTranslation } from 'react-i18next'

import sport_bar from '../../../assets/image/sport-bar.jpg'

interface IProps {
  className?: string
  image?: string
}

const ClubSportBar: FC<IProps> = ({ className = '', image }) => {
  const { t } = useTranslation()

  return (
    <div className={`sport-bar ${className}`}>
      <Container>
        <div className="sport-bar__body">
          <div className="sport-bar__content">
            <div className="sport-bar__title">
              {t('there_is_a_sports_bar_in_the_hall')}
            </div>
            <div className="sport-bar__text">
              {t('protein_shakes_fat_burners')}
            </div>
          </div>
          <div className="sport-bar__img">
            <img
              src={image}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null
                currentTarget.src = sport_bar
              }}
              alt="img"
            />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default ClubSportBar
