import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '../../api'
import { IFeedback } from '../../models/Feedback'
import { IPagination } from '../../models/Pagination'
import { AxiosError } from 'axios'

export const fetchFeedback = createAsyncThunk(
  'feedback/fetchFeedback:load',
  async function (page: number) {
    const response = await api.get('feedback', {
      params: {
        page,
      },
    })
    return response.data
  }
)

export const sendFeedback = createAsyncThunk(
  'feedback/sendFeedback:load',
  async function (payload: any, { rejectWithValue }) {
    try {
      const params = {
        ...payload,
      }
      const response = await api.post('feedback', params)

      return response.data
    } catch (err) {
      const error = err as AxiosError
      return rejectWithValue(error?.response?.data)
    }
  }
)

interface IFeedbackState {
  data: IFeedback[]
  pagination: IPagination | null
  isLoading: boolean
  errors: any
}

const initialState = {
  data: [],
  pagination: null,
  isLoading: false,
  errors: null,
} as IFeedbackState

const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    clearErrors(state) {
      state.errors = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFeedback.fulfilled, (state, action) => {
        state.data = action.payload.data as IFeedback[]
        state.pagination = action.payload.meta
      })
      .addCase(sendFeedback.rejected, (state, action: any) => {
        state.errors = action.payload?.errors
      })
  },
})

export const feedbackReducers = feedbackSlice.reducer
export const { clearErrors } = feedbackSlice.actions
