import React, { FC } from 'react'
import { Container } from '../index'
import ClubsItem from './ClubsItem'
import './ClubsItems.scss'
import { IClubs } from '../../models/Clubs'

interface IProps {
  items: IClubs[]
}

const ClubsItems: FC<IProps> = (props) => {
  return (
    <div className="clubs-items">
      <Container>
        <div className="clubs-items__body">
          {props.items.map((item: IClubs) => (
            <ClubsItem
              key={item.id}
              id={item.slug}
              name={item.name}
              address={item.address}
              image={item.image}
              new={item.new}
              not_working={item.not_working}
              created_at={item.created_at}
            />
          ))}
        </div>
      </Container>
    </div>
  )
}

export default ClubsItems
