import React, { FC } from 'react'
import './BasePreloader.scss'
import { useAppSelector } from '../../../hooks/redux'
import { RootState } from '../../../store'

interface IBasePreloader {
  className?: string
  isPending?: boolean
}

const BasePreloader: FC<IBasePreloader> = ({ className = '', isPending }) => {
  const { isGlobalPending } = useAppSelector(
    (state: RootState) => state.loading
  )

  return (
    <>
      {(isGlobalPending || isPending) && (
        <div key="preloader" className={`base-preloader ${className}`}>
          <div className="base-preloader__wrap">
            <div className="base-preloader__inner">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default BasePreloader
