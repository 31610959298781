import React from 'react'
import './Button.scss'
import './ButtonArrow.scss'
import { BaseSvg } from '../index'
import AppLink from '../Base/AppLink'

interface IProps {
  children?: React.ReactNode
  className?: string
  color?: '_orange' | '_green'
  link?: string
  onClick?: () => void
}

const ButtonArrow = ({
  children,
  className,
  color = '_orange',
  onClick,
  link,
  ...props
}: IProps) => {
  return (
    <>
      {link ? (
        <AppLink
          to={link}
          className={`button-arrow ${className ? className : ''} ${color} ${
            className ? className : ''
          }`}
        >
          {children}
          <BaseSvg name="ico-arrow-right-short" />
        </AppLink>
      ) : (
        <button
          className={`button-arrow ${className ? className : ''} ${color} ${
            className ? className : ''
          }`}
          onClick={onClick}
          {...props}
        >
          {children}
          <BaseSvg name="ico-arrow-right-short" />
        </button>
      )}
    </>
  )
}

export default ButtonArrow
