import React, { FC } from 'react'
import './ClubPriceBanner.scss'
import banner from '../../../assets/image/banner.jpg'
import banner_mob from '../../../assets/image/banner_mob.jpg'

interface IProps {
  title: string
  sub_title: string
  mobile_title: string
  mobile_sub_title: string
}

const ClubPriceBanner: FC<IProps> = (props) => {
  return (
    <div className="price-banner">
      <picture className="price-banner__picture">
        <source
          media="(min-width: 1024px)"
          srcSet={banner}
          className="price-banner__picture-img"
        />
        <img
          src={banner_mob}
          className="price-banner__picture-img"
          alt="banner"
        />
      </picture>

      <div className="price-banner__title d-md-none">
        {props.title}
        <span> {props.sub_title}</span>
      </div>
      <div className="price-banner__title d-none d-md-block">
        {props.mobile_title}
        <span> {props.mobile_sub_title}</span>
      </div>
    </div>
  )
}

export default ClubPriceBanner
