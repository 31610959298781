import windowDimensions from '../hooks/windowDimensions'
import * as React from 'react'
import {useEffect, useState} from 'react'
import {Styles} from "react-modal";
import * as CSS from 'csstype'
import {Property} from 'csstype'

interface IBasicStyleModal extends Styles {
    overlay?: IOverlay
    content?: IContent
}

interface IOverlay extends React.CSSProperties {
}

interface IContent extends React.CSSProperties {
}

export default function useBasicStyleModal() {
    const overlayBase: React.CSSProperties = {
        backgroundColor: 'rgba(7,7,19,0.25)',
        zIndex: 99,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'auto',
    }

    const baseContent: React.CSSProperties = {
        position: "static" as Property.Position,
        boxShadow: '0 6.5rem 10rem rgba(7, 7, 19, 0.1)',
        padding: 0,
        border: 'none',
        color: 'inherit',
        backgroundColor: 'transparent',
    }
    const {windowWidth, md} = windowDimensions()
    const [basicStyleModal, setBasicStyleModal] = useState<Styles>({})

    useEffect(() => {
        if (windowWidth <= md) {
            setBasicStyleModal({
                ...basicStyleModal,
                content: {
                    borderRadius: '2.5rem 2.5rem 0 0',
                    ...baseContent,
                },
                overlay: {
                    justifyContent: 'flex-end',
                    ...overlayBase,
                },
            })
        } else {
            setBasicStyleModal({
                ...basicStyleModal,
                content: {
                    borderRadius: '4.5rem',
                    ...baseContent,
                },
                overlay: {
                    justifyContent: 'center',
                    ...overlayBase,
                },
            })
        }
    }, [windowWidth])

    return {
        basicStyleModal,
    }
}
