import React, { FC, useEffect, useState } from 'react'
import './BaseNew.scss'
import { useTranslation } from 'react-i18next'

interface IBaseNew {
  isNotWorking?: boolean
  isOpeningSoon?: boolean
  className?: string
}

const BaseNew: FC<IBaseNew> = (props) => {
  const { t } = useTranslation()
  const [label, setLabel] = useState<string>('new')

  useEffect(() => {
    if (props.isNotWorking) {
      setLabel(`${t('not_working')}`)
    } else if (props.isOpeningSoon) {
      setLabel(`${t('opening_soon')}`)
    }
  }, [])

  return (
    <div
      className={`base-new ${props.className ? props.className : ''} ${
        props.isNotWorking ? '_red' : ''
      } ${props.isOpeningSoon ? '_orange' : ''}`}
    >
      {label}
    </div>
  )
}

export default BaseNew
