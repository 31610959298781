import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '../../api'
import { IClubs } from '../../models/Clubs'

export const fetchClub = createAsyncThunk(
  'club/fetchClub:load',
  async function (id: string | undefined) {
    if (!id) return
    const response = await api.get(`clubs/${id}`)
    return response.data
  }
)

interface clubsState {
  club: IClubs | null
}

const initialState = {
  club: null,
  polygon: null,
} as clubsState

const clubSLice = createSlice({
  name: 'club',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchClub.fulfilled, (state, action) => {
      state.club = action.payload.data
    })
  },
})

export const clubReducers = clubSLice.reducer
