import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import Promo from '../../components/Promo/Promo'
import { BaseBorderMobile, BaseSvg, Trainer } from '../../components'
import './Trainer.scss'
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import { IBreadCrumbs } from '../../models/BreadCrumbs'
import TickerReact from '../../components/TickerReact/TickerReact'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { fetchClub } from '../../store/slices/club'
import { RootState } from '../../store'
import { fetchTrainer } from '../../store/slices/trainer'
import { useTranslation } from 'react-i18next'

const Index = () => {
  const { t } = useTranslation()
  const [breadCrumbs, setBreadCrumbs] = useState<IBreadCrumbs[]>([])
  const dispatch = useAppDispatch()
  const { id, slug } = useParams()
  const { isGlobalPending } = useAppSelector(
    (state: RootState) => state.loading
  )
  const { club } = useAppSelector((state: RootState) => state.club)
  const { trainer } = useAppSelector((state: RootState) => state.trainer)

  useEffect(() => {
    if (!club) {
      dispatch(fetchClub(id))
    }
    if (id && slug) {
      dispatch(fetchTrainer({ id, slug }))
    }
  }, [])

  useEffect(() => {
    if (trainer && club) {
      setBreadCrumbs([
        {
          id: 1,
          name: t('our_clubs'),
          link: '/clubs',
        },
        {
          id: 2,
          name: `PULSE GYM ${club?.name}`,
          link: `/club/${club.slug}`,
        },
        {
          id: 3,
          name: trainer?.name,
        },
      ])
    }
  }, [trainer, club])

  return (
    <>
      <Helmet>
        {trainer?.seo?.title && <title>{trainer.seo.title}</title>}
        {trainer?.seo?.description && (
          <meta name="description" content={`${trainer?.seo?.description}`} />
        )}
      </Helmet>
      <div className="trainer-page">
        <BaseBorderMobile />
        {trainer && (
          <>
            <Promo className="d-md-none" title={trainer.name}>
              <div className="trainer-page__title">
                <BaseSvg name="ico-strong" />
                {trainer?.is_personal_coach
                  ? t('individual_program')
                  : t('personal_trainer')}
              </div>
            </Promo>
            <BreadCrumbs dataList={breadCrumbs} />
            {!isGlobalPending && <Trainer trainer={trainer} />}
          </>
        )}

        <TickerReact className="trainer-page__ticker d-md-none _mb-2" />
      </div>
    </>
  )
}

export default Index
