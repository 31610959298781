import React, {FC, useContext} from 'react'
import './ClubScheduleTable.scss'
import {ScheduleContext} from '../../../pages/Club'
import {DaysOfWeekEnum, IScheduleItem} from '../../../models/Clubs'
import {useTranslation} from 'react-i18next'
import moment from "moment";

const ClubScheduleTable: FC = () => {
    const {t} = useTranslation()
    const data = useContext(ScheduleContext)

    return (
        <div className="schedule-table">
            <div className="schedule-table__top schedule-table__row">
                <div className="schedule-table__top-item"> {t('direction')}</div>
                <div className="schedule-table__top-item">{t('mon')}</div>
                <div className="schedule-table__top-item">{t('tue')}</div>
                <div className="schedule-table__top-item">{t('wed')}</div>
                <div className="schedule-table__top-item">{t('thu')}</div>
                <div className="schedule-table__top-item">{t('fri')}</div>
                <div className="schedule-table__top-item">{t('sat')}</div>
            </div>

            {data?.schedule &&
                data.schedule.map((item: IScheduleItem, index) => (
                    <li key={index} className="schedule-table__row schedule-table__item">
                        <div className="schedule-table__item-title schedule-table__item-item">
                            {item.service}
                            {item.recruiting ? (
                                <div className="schedule-table__item-recruiting">
                                    {t('is_recruiting')}
                                </div>
                            ) : null}
                        </div>

                        {item &&
                            Object.keys(item).filter(value => {
                                return value in DaysOfWeekEnum
                            }).map(
                                (element, index) => (
                                    <div key={index} className="schedule-table__item-item _start">
                                        {item[element].map(
                                            (scheduleItem: string, index) => (
                                                <span key={index}>
                                                  {scheduleItem ? moment(scheduleItem, "HH:mm").format('HH:mm') : null}
                                                </span>
                                                )
                                            )}
                                    </div>
                                )
                            )}
                    </li>
                ))}
        </div>
    )
}

export default ClubScheduleTable
