import React, { memo } from 'react'
import { ButtonArrowRight, Container } from '../../index'
import './Corporate.scss'
import CorporateBenefits from './CorporateBenefits'
import { useTranslation } from 'react-i18next'

const Corporate = () => {
  const { t } = useTranslation()

  return (
    <section className="corporate">
      <Container>
        <div className="corporate__head">
          <div className="corporate__title">{t('why_pulse_gym')}</div>
          <ButtonArrowRight to="/clubs/" className="d-md-none">
            {t('choose_a_club_3')}
          </ButtonArrowRight>
        </div>
        <CorporateBenefits />
      </Container>
    </section>
  )
}

export default memo(Corporate)
