import React, { FC, useEffect, useRef, useState, memo } from 'react'
import { Helmet } from 'react-helmet'
import Promo from '../../components/Promo/Promo'
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import { IBreadCrumbs } from '../../models/BreadCrumbs'
import {
  BaseBorderMobile,
  ClubListTitle,
  ClubPlans,
  ClubSchedule,
  ClubServices,
  ClubSlider,
  ClubSportBar,
  ClubTop,
  ClubTrainers,
  Container,
  Ticker,
} from '../../components'
import './Club.scss'
import ClubPrice from '../../components/Club/ClubPrice/ClubPrice'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { fetchClub } from '../../store/slices/club'
import { RootState } from '../../store'
import { ISchedule } from '../../models/Clubs'
import { Button } from '../../components'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

interface ISchedules {
  schedule: ISchedule
}

export const ScheduleContext = React.createContext<ISchedules | null>(null)

const Club: FC = () => {
  const { t } = useTranslation()
  const now = moment()
  const [breadCrumbs, setBreadCrumbs] = useState<IBreadCrumbs[]>([
    {
      id: 1,
      name: t('our_clubs'),
      link: '/clubs',
    },
  ])
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const { club } = useAppSelector((state: RootState) => state.club)
  const priceScheduleBlock = useRef<null | HTMLDivElement>(null)

  useEffect(() => {
    dispatch(fetchClub(id))
  }, [])

  useEffect(() => {
    if (club) {
      setBreadCrumbs((prevState: IBreadCrumbs[]) => [
        prevState[0],
        { id: 2, name: `PULSE GYM ${club.address}` },
      ])
    }
  }, [club])

  const callBack = () => {
    navigate({
      pathname,
      search: `?tab=schedule`,
    })

    setTimeout(() => {
      const top = priceScheduleBlock.current?.offsetTop
      window.scrollTo({
        top,
        behavior: 'smooth',
      })
    }, 10)
  }

  return (
    <>
      {club && (
        <>
          <Helmet>
            {club?.seo?.title && <title>{club.seo.title}</title>}
            {club?.seo?.description && (
              <meta name="description" content={`${club?.seo?.description}`} />
            )}
          </Helmet>
          <div className="club-page">
            <BaseBorderMobile />
            {club.not_working ? (
              <div className="club-page__status _red d-none d-md-block">
                {t('not_working')}
              </div>
            ) : null}
            {moment(club.created_at) < now && (
              <ClubSlider media={club.media} className="d-none d-md-block" />
            )}
            <Promo
              title={moment(club.created_at) > now ? club.name : club.address}
            >
              {club.not_working ? (
                <div className="club-page__status _red">{t('not_working')}</div>
              ) : moment(club.created_at) > now ? null : (
                <ClubListTitle />
              )}
            </Promo>
            <BreadCrumbs dataList={breadCrumbs} />
            {moment(club.created_at) < now ? (
              <>
                <div className="d-none d-md-block">
                  <Container>
                    <div className="club-page__title">
                      pulse gym {club.address}
                    </div>
                    <ClubListTitle />
                  </Container>
                </div>
                <ClubTop
                  key={club.id}
                  slug={club.slug}
                  address={club.address}
                  gMapsUrl={club.g_maps_url}
                  lat={Number(club.lat)}
                  lng={Number(club.lng)}
                  phone={club.phone}
                  name={club.name}
                  scheduler={club.scheduler}
                />
                <ClubSlider media={club.media} className="d-md-none" />
                <ClubServices services={club.services} />
                <div
                  ref={priceScheduleBlock}
                  className="club-page__wrapper d-md-none"
                >
                  {searchParams.get('tab') === 'schedule' ? (
                    <ScheduleContext.Provider
                      value={{
                        schedule: club.schedule,
                      }}
                    >
                      <ClubSchedule callBack={callBack} link={`/club/${id}/`} />
                    </ScheduleContext.Provider>
                  ) : (
                    <ClubPrice
                      link={`/club/${id}/?tab=schedule`}
                      price={club.price}
                      callBack={callBack}
                    />
                  )}
                </div>

                <div className="club-page__buttons-wrap d-none d-md-block">
                  <Container>
                    <div className="club-page__buttons">
                      <Button
                        to={`/clubs-price/${id}`}
                        color="_green"
                        className="club-page__btn"
                      >
                        {t('price_list')}
                      </Button>
                      <Button
                        to={`/clubs-schedule/${id}`}
                        className="club-page__btn"
                      >
                        {t('timetable')}
                      </Button>
                    </div>
                  </Container>
                </div>

                <ClubSportBar image={club.image_bar} className="d-md-none" />
                <ClubTrainers coaches={club.coaches} />
                <Ticker className="d-none d-md-block" />
                <ClubSportBar
                  image={club.image_bar}
                  className="d-none d-md-block"
                />
                <ClubPlans />
              </>
            ) : (
              <div className="d-none d-md-block">
                <Container>
                  <div className="club-page__title _mt-20">{club.name}</div>
                </Container>
              </div>
            )}
          </div>
        </>
      )}
      {moment(club?.created_at) < now && <Ticker className="_mb-2 d-md-none" />}
    </>
  )
}

export default memo(Club)
