import React, {FC, useEffect, useState} from 'react'
import {Container, Map} from '../../index'
import './Map.scss'
import {IMarkers} from '../../../models/Markers'
import {IPolygon} from '../../../models/Polygon'
import {useTranslation} from 'react-i18next'
import {useLoadScript} from "@react-google-maps/api";
import {useAppSelector} from "../../../hooks/redux";
import {RootState} from "../../../store";

interface IProps {
    markers: IMarkers[]
    polygon?: IPolygon[]
    className?: string
    hideTitle?: boolean
    mapUrl?: string
}

const HomeMap: FC<IProps> = (props) => {
    const {t} = useTranslation()
    const [key, setKey] = useState(1)

    useEffect(() => {
        setKey(key + 1)
    }, [])

    return (
        <section className={`map-home ${props.className ? props.className : ''}`}>
            <Container>
                {!props.hideTitle && (
                    <div className="map-home__title d-none d-md-block">
                        {t('location_of_clubs')}
                    </div>
                )}

                {/*<Map
                    key={key}
                    polygon={props.polygon}
                    markers={props.markers}
                    className="map-home__map"
                />*/}

                <div className="googlemap">
                    <iframe src={props.mapUrl}
                            width="640" height="480"></iframe>
                </div>
            </Container>
        </section>
    )
}
{/*
<iframe
    width="1024"
    height="600"
    style={{border: 0}}
    loading="lazy"
    allowFullScreen
    referrerPolicy="no-referrer-when-downgrade"
    src="https://www.google.com/maps/embed/v1/place?key=AIzaSyA9kNrrisnOokagweuNPbJRbexY65PTeyo&q=Pulse+Gym,+проспект+Людвига+Свободи,+29">
</iframe>*/}

export default HomeMap
