import React, { FC } from 'react'
import './BlogInner.scss'
import { BlogDate, BlogTags } from '../index'
import moment from 'moment'
import { ITags } from '../../models/Tags'
import AppLink from '../Base/AppLink'

interface IProps {
  id: number | string
  image: string
  tags: ITags[]
  title: string
  date: string
  className?: string
}

const BlogInner: FC<IProps | any> = (props) => {
  return (
    <div className={`blog-inner ${props.className ? props.className : ''}`}>
      <AppLink to={`/${props.id}/`} className="blog-inner__top">
        <img src={props.image} alt="" />
      </AppLink>
      <div className="blog-inner__info">
        <BlogDate
          className="blog-inner__date"
          date={moment(props.date).format('DD.MM.YYYY')}
        />
        <BlogTags className="blog-inner__tags d-md-none" tags={props.tags} />
      </div>
      <AppLink to={`/${props.id}/`} className="blog-inner__title">
        {props.title}
      </AppLink>
    </div>
  )
}

export default BlogInner
