import React from 'react'
import { BaseSvg } from '../../index'
import './CorporateBenefits.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper'
import { useTranslation } from 'react-i18next'

SwiperCore.use([Navigation])

const CorporateBenefits = () => {
  const { t } = useTranslation()

  const items = [
    {
      icon: 'location',
      text: t('convenient_location_of_our_clubs'),
    },
    {
      icon: 'comfort',
      text: t('comfortable_locker_rooms_and_relaxation_area'),
    },
    {
      icon: 'equipment',
      text: t('modern_professional_equipment'),
    },
    {
      icon: 'price',
      text: t('affordable_prices_for_each_guest'),
    },
    {
      icon: 'user',
      text: t('individual_approach_to_each_client'),
    },
  ]
  return (
    <>
      <div className="corporate-benefits d-md-none">
        {items.map((item, index) => (
          <React.Fragment key={index}>
            <div className="corporate-benefits__item">
              <div className="corporate-benefits__icon">
                <BaseSvg name={`ico-${item.icon}`} />
              </div>
              <div className="corporate-benefits__text">{item.text}</div>
            </div>
            {index < items.length - 1 && (
              <div className="corporate-benefits__dots" />
            )}
          </React.Fragment>
        ))}
      </div>
      <Swiper
        loop
        slidesPerView={'auto'}
        className="corporate-benefits__slider d-none d-md-block"
        navigation={{
          nextEl: '.corporate-benefits__btn-next',
          prevEl: '.corporate-benefits__btn-prev',
        }}
      >
        {items.map((item, index) => (
          <SwiperSlide key={index} className="corporate-benefits__slide ">
            <div className="corporate-benefits__item">
              <div className="corporate-benefits__icon">
                <BaseSvg name={`ico-${item.icon}`} />
              </div>
              <div className="corporate-benefits__text">{item.text}</div>
            </div>
          </SwiperSlide>
        ))}
        <div className="corporate-benefits__wrapper">
          <button className="corporate-benefits__btn corporate-benefits__btn-prev">
            <BaseSvg name="ico-arrow-right" />
          </button>
          <button className="corporate-benefits__btn corporate-benefits__btn-next">
            <BaseSvg name="ico-arrow-right" />
          </button>
          <div className="corporate-benefits__dots corporate-benefits__dots-prev" />
          <div className="corporate-benefits__dots corporate-benefits__dots-next" />
        </div>
      </Swiper>
    </>
  )
}

export default CorporateBenefits
