import React from 'react'
import './Footer.scss'
import { BaseSvg, ButtonArrowRight, Container } from '../index'
import { copyright } from '../../helpers/copyright'
import AppLink from '../Base/AppLink'
import { useTranslation } from 'react-i18next'

const Footer = ({ className = '' }) => {
  const { i18n, t } = useTranslation()
  const email = (window as any)?.setting?.email || ''
  const instagram = (window as any)?.setting?.instagram || ''
  const facebook = (window as any)?.setting?.facebook || ''
  const youtube = (window as any)?.setting?.youtube || ''

  const items = [
    {
      name: t('home'),
      link: '/',
    },
    {
      name: t('contacts'),
      link: '/contact/',
    },
    {
      name: t('all_clubs'),
      link: '/clubs/',
    },
    {
      name: t('blog'),
      link: '/information/',
    },
    {
      name: t('reviews'),
      link: '/reviews/',
    },
    {
      name: t('photo'),
      link: '/gallery/',
    },
  ]

  return (
    <footer className={`footer ${className}`}>
      <Container>
        <div className="footer__body d-md-none">
          <div className="footer__wrapper">
            <div className="footer__col _sb">
              <a className="footer__mail" href={`mailto:${email}`}>
                {email}
              </a>
              <p className="footer__copyright">
                {t('pulse_gym_a_network_of_sports_clubs_in_Kharkov')}{' '}
                {copyright()}
              </p>
            </div>
            <ul className="footer__nav">
              {items.map((item) => (
                <li key={item.link}>
                  <AppLink isNavLink className="footer__link" to={item.link}>
                    {item.name}
                  </AppLink>
                </li>
              ))}
            </ul>
            <div className="footer__col">
              {facebook ? (
                <a href={facebook} target="_blank" className="footer__link">
                  <BaseSvg name="ico-facebook" />
                  Facebook
                </a>
              ) : null}
              {instagram ? (
                <a href={instagram} target="_blank" className="footer__link">
                  <BaseSvg name="ico-instagram" />
                  Instagram
                </a>
              ) : null}
              {youtube ? (
                <a href={youtube} target="_blank" className="footer__link">
                  <BaseSvg name="ico-youtube" />
                  Youtube
                </a>
              ) : null}
            </div>
          </div>
          <div className="footer__col _sb">
            <ButtonArrowRight
              to={
                i18n.language === 'ru'
                  ? '/regulations/pravyla-poseshhenyya-sportyvnogo-kluba-tehnyka-bezopasnosty/'
                  : '/regulations/pravyla-vidviduvannya-sportyvnogo-klubu-tehnika-bezpeky/'
              }
            >
              {t('visiting_rules')}
            </ButtonArrowRight>
            <a
              href="https://emexes.com/"
              target="_blank"
              className="footer__created"
            >
              <BaseSvg name="ico-created" />
            </a>
          </div>
        </div>
        <div className="footer__body d-none d-md-flex">
          <AppLink
            to={
              i18n.language === 'ru'
                ? '/regulations/pravyla-poseshhenyya-sportyvnogo-kluba-tehnyka-bezopasnosty/'
                : '/regulations/pravyla-vidviduvannya-sportyvnogo-klubu-tehnika-bezpeky/'
            }
            className="footer__rules"
          >
            {t('visiting_rules')}
          </AppLink>
          <a href={facebook} target="_blank" className="footer__link">
            <BaseSvg name="ico-facebook" />
            Facebook
          </a>
          <a className="footer__mail" href={`mailto:${email}`}>
            {email}
          </a>
          <p className="footer__copyright">
            {t('pulse_gym_a_network_of_sports_clubs_in_Kharkov')} {copyright()}
          </p>
          <a
            href="https://emexes.com/"
            target="_blank"
            className="footer__created"
          >
            <BaseSvg name="ico-created-center" />
          </a>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
