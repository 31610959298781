import React from 'react'
import promo_bg from '../../assets/image/promo_bg.jpg'
import './Promo.scss'
import { Container } from '../index'

interface IProps {
  title: string
  disabledBorder?: boolean
  children?: React.ReactNode
  className?: string
}

const Promo = ({ title, disabledBorder, children, className = '' }: IProps) => {
  return (
    <div className={`promo ${!disabledBorder ? 'border' : ''} ${className}`}>
      <Container>
        <div className="promo__inner">
          <div className="promo__title title">{title}</div>
          <div className="promo__info">{children}</div>
        </div>
      </Container>
      <picture className="promo__bg d-md-none">
        <img src={promo_bg} alt="promo" className="promo__bg-img" />
      </picture>
    </div>
  )
}

export default Promo
