import React from 'react'
import './BaseCheckbox.scss'
import { BaseSvg } from '../../index'
import { useSearchParams } from 'react-router-dom'
import AppLink from '../AppLink'
import { useTranslation } from 'react-i18next'

const BaseCheckbox = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()

  return (
    <>
      <div
        className={`base-checkbox d-md-none ${
          searchParams.get('tab') === 'map' ? 'active' : ''
        }`}
      >
        <AppLink to="/clubs/" className="base-checkbox__item _left">
          {t('list')}
        </AppLink>
        <AppLink
          to={searchParams.get('tab') === 'map' ? '/clubs' : '/clubs?tab=map'}
          className="base-checkbox__toggle"
        />
        <AppLink to="/clubs?tab=map" className="base-checkbox__item _right">
          {t('on_the_map')}
        </AppLink>
      </div>
      {searchParams.get('tab') !== 'map' ? (
        <AppLink
          to="/clubs?tab=map"
          className="base-checkbox__item-mobile d-none d-md-flex"
        >
          {t('on_the_map')}
          <BaseSvg name="ico-map" />
        </AppLink>
      ) : (
        <AppLink
          to="/clubs/"
          className="base-checkbox__item-mobile d-none d-md-flex"
        >
          {t('list')}
          <BaseSvg name="ico-list" />
        </AppLink>
      )}
    </>
  )
}

export default BaseCheckbox
