import React, { FC } from 'react'
import './BlogDate.scss'
import { BaseSvg } from '../index'

interface IProps {
  date: string
  className?: string
}

const BlogDate: FC<IProps> = (props) => {
  return (
    <time
      dateTime={props.date}
      className={`blog-date ${props.className ? props.className : ''}`}
    >
      <BaseSvg name="ico-time" />
      {props.date}
    </time>
  )
}

export default BlogDate
