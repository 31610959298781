import React, { FC } from 'react'
import './BlogTags.scss'
import { ITags } from '../../models/Tags'
import AppLink from '../Base/AppLink'

interface IProps {
  tags: ITags[]
  className?: string
}

const BlogTags: FC<IProps> = ({ tags, className }) => {
  return (
    <>
      {tags && tags.length ? (
        <div className={`blog-tags ${className ? className : ''}`}>
          {tags.map((item: ITags, index) => (
            <AppLink
              to={`/information?tag=${item.slug}`}
              key={index}
              className="blog-tags__item"
            >
              <span>#{item.name}</span>
              {index < tags.length - 1 && <>,&nbsp;</>}
            </AppLink>
          ))}
        </div>
      ) : (
        ''
      )}
    </>
  )
}

export default BlogTags
