import React, {FC} from 'react'
import {Outlet} from 'react-router-dom'
import {Footer, Header} from '../components'
import SvgList from '../components/Svg/SvgList'
import BasePreloader from '../components/Base/BasePreloader/BasePreloader'
import {ToastContainer} from 'react-toastify'
import FeedbackForm from "../components/FeedbackForm/FeedbackForm";

const LayoutDefault: FC = () => {
    return (
        <div className="layout">
            <FeedbackForm/>
            <Header className="layout__header"/>
            <div className="layout__wrap">
                <Outlet/>
            </div>
            <BasePreloader className="_global"/>
            <Footer className="layout__footer"/>
            <SvgList/>
            <ToastContainer autoClose={2000} closeButton={false} icon={false}/>
        </div>
    )
}

export default LayoutDefault
