import React, { FC } from 'react'
import { BaseSvg, ButtonArrow, Container } from '../../index'
import './ClubPrice.scss'
import ClubPriceList from './ClubPriceList'
import { IPrice } from '../../../models/Clubs'
import { useTranslation } from 'react-i18next'

interface IProps {
  link: string
  price: IPrice[]
  callBack?: any
}

const ClubPrice: FC<IProps> = (props) => {
  const { t } = useTranslation()
  const handlerToBLock = () => {
    props.callBack()
  }

  return (
    <div className="club-price">
      <Container>
        <div className="club-price__body">
          <div className="club-price__title">
            <h2 className="title-club">{t('price_list')}</h2>
            <ButtonArrow link={props.link}>{t('timetable')}</ButtonArrow>
          </div>
          <div className="club-price__info">
            {props.price.map((item: IPrice, index: number) => (
              <ClubPriceList key={`${item.id}_${index}`} item={item} />
            ))}
          </div>
        </div>
      </Container>
      <button onClick={handlerToBLock} className="club-price__schedule">
        <Container>
          <div className="club-price__schedule-body">
            <BaseSvg name="ico-lightning" />
            <span className="club-price__schedule-link">
              {t('see_class_schedule')}
            </span>
            <BaseSvg name="ico-lightning" />
          </div>
        </Container>
      </button>
    </div>
  )
}

export default ClubPrice
