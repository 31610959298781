import React, { FC } from 'react'
import './ClubPriceList.scss'
import { IPrice, ISubPrice } from '../../../models/Clubs'
import { useTranslation } from 'react-i18next'
import ClubPriceBanner from './ClubPriceBanner'
import ClubPriceCoachServices from './ClubPriceCoachServices'

interface IProps {
  item: IPrice
}

const ClubPriceList: FC<IProps> = (props) => {
  const { t } = useTranslation()

  return (
    <>
      <div className="price-list">
        <h3 style={{ color: props.item.color }} className="price-list__title">
          {props.item?.name}
          {props.item?.new ? <span>New</span> : null}
          {props.item?.actia ? <span>{t('promotion')}</span> : null}
        </h3>
        <ul className="price-list__items">
          {props.item?.price &&
            props.item?.price.map((item: ISubPrice) => (
              <li key={item.id} className="price-list__item">
                <span className="price-list__item-text">{item.name}</span>
                <span className="price-list__item-price">{item.price}</span>
              </li>
            ))}
        </ul>
        {props.item?.comment && (
          <div className="price-list__notice">{props.item?.comment}</div>
        )}
      </div>
      {props.item?.baner.length
        ? props.item?.baner.map((item) => {
            if (item.type === 'two-free-training') {
              return (
                <ClubPriceBanner
                  key={item.type}
                  title={item.title}
                  sub_title={item.sub_title}
                  mobile_title={item.mobile_title}
                  mobile_sub_title={item.mobile_sub_title}
                />
              )
            } else if (item.type === 'freeze') {
              return (
                <ClubPriceCoachServices
                  key={item.type}
                  title={item.title}
                  sub_title={item.sub_title}
                  mobile_title={item.mobile_title}
                  mobile_sub_title={item.mobile_sub_title}
                />
              )
            } else if (item.type === 'personal-coach') {
              return (
                <ClubPriceCoachServices
                  key={item.type}
                  slug={item.slug}
                  title={item.title}
                  sub_title={item.sub_title}
                  mobile_title={item.mobile_title}
                  mobile_sub_title={item.mobile_sub_title}
                />
              )
            } else {
              return null
            }
          })
        : null}
    </>
  )
}

export default ClubPriceList
