import React from 'react'
import { useTranslation } from 'react-i18next'
import './Header.scss'
import AppLink from '../Base/AppLink'

interface INavItem {
  link: string
  name: string
}

const HeaderNav = () => {
  const { t } = useTranslation()

  const items = [
    {
      name: t('clubs'),
      link: '/clubs/',
    },
    {
      name: t('blog'),
      link: '/information/',
    },
    {
      name: t('reviews'),
      link: '/reviews/',
    },
    {
      name: t('contacts'),
      link: '/contact/',
    },
    {
      name: t('photo'),
      link: '/gallery/',
    },
  ]
  return (
    <ul className="header__nav d-md-none">
      {items.map((item: INavItem) => (
        <li key={item.link}>
          <AppLink isNavLink className="header__link" to={item.link}>
            {item.name}
          </AppLink>
        </li>
      ))}
    </ul>
  )
}

export default HeaderNav
