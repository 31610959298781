import React from 'react'
import { Button, Container } from '../../index'
import './ClubPlans.scss'
import { useTranslation } from 'react-i18next'

const ClubPlans = () => {
  const { t } = useTranslation()

  return (
    <div className="club-plans d-md-none">
      <Container>
        <div className="club-plans__body">
          <h2 className="club-plans__title">
            {t('you_can_see_the_general_plans')}
          </h2>
          <div className="club-plans__buttons">
            <Button
              to="/information?tag=plan-zaniat"
              className="club-plans__btn _big"
              color="_green"
            >
              {t('workout_plans')}
            </Button>
            <Button
              to="/information?tag=xarcuvannia"
              className="club-plans__btn _big"
            >
              {t('nutritional_advice')}
            </Button>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default ClubPlans
