import React, { FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'
import Promo from '../../components/Promo/Promo'
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import { IBreadCrumbs } from '../../models/BreadCrumbs'
import { BaseSvg, BlogDate, BlogTags, Container } from '../../components'
import TickerReact from '../../components/TickerReact/TickerReact'
import './OneBlog.scss'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { fetchBlog } from '../../store/slices/blog'
import { RootState } from '../../store'
import moment from 'moment'
import AppLink from '../../components/Base/AppLink'
import { useTranslation } from 'react-i18next'
import Error from '../Error/Error'

const OneBlog: FC = () => {
  const { t } = useTranslation()
  const [breadCrumbs, setBreadCrumbs] = useState<IBreadCrumbs[]>([])
  const dispatch = useAppDispatch()
  const { id } = useParams()
  const { blog, errors } = useAppSelector((state: RootState) => state.blog)

  useEffect(() => {
    dispatch(fetchBlog(id))
  }, [id])

  useEffect(() => {
    if (blog?.title) {
      setBreadCrumbs([
        {
          id: 1,
          name: t('blog'),
          link: '/information',
        },
        {
          id: 2,
          name: blog?.title,
        },
      ])
    }
  }, [blog?.title])

  return errors ? (
    <Error />
  ) : (
    <>
      <Helmet>
        {blog?.seo?.title && <title>{blog.seo.title}</title>}
        {blog?.seo?.description && (
          <meta name="description" content={`${blog?.seo?.description}`} />
        )}
      </Helmet>
      <div className="one-blog">
        <Promo
          title={blog && blog?.tags?.length ? `#${blog.tags[0].name}` : '#'}
        >
          {blog?.next && (
            <AppLink to={`/${blog.next}/`} className="one-blog__btn">
              <span className="d-md-none">{t('read_next_article')}</span>
              <span className="d-none d-md-block">{t('further')}</span>
              <BaseSvg name="ico-arrow-right-long" />
            </AppLink>
          )}
        </Promo>
        <BreadCrumbs dataList={breadCrumbs} />
        {blog && (
          <Container className="one-blog__container ">
            <div className="one-blog__inner clearfix">
              <div className="one-blog__picture">
                <img src={blog.image} alt="" className="one-blog__img" />
              </div>
              <BlogDate
                date={moment(blog.date).format('DD.MM.YYYY')}
                className="one-blog__mobile-date d-none d-md-inline-flex"
              />
              <div className="one-blog__info">
                <h1 className="one-blog__title">{blog?.title}</h1>
                <div className="one-blog__info-content d-md-none">
                  <BlogDate date={moment(blog.date).format('DD.MM.YYYY')} />
                  <BlogTags tags={blog.tags} />
                </div>

                <div className="one-blog__info-text">
                  {ReactHtmlParser(blog.description)}
                </div>
              </div>
            </div>
            <div className="one-blog__tag d-none d-md-flex">
              <BlogTags tags={blog.tags} className="one-blog__tag-list" />
            </div>
          </Container>
        )}
        <TickerReact className="one-blog__ticker d-md-none" />
      </div>
    </>
  )
}

export default OneBlog
