import React, { FC, useEffect, memo } from 'react'
import { BaseSvg, ButtonArrowRight, ButtonSlider, Container } from '../../index'
import './SmartFit.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper'
import girl from '../../../assets/image/smart-fit-girl.png'
import bg from '../../../assets/image/smart-fit-bg.jpg'
import bg_mobile from '../../../assets/image/smart-fit-bg-mobile.png'
import { IMedia } from '../../../models/Clubs'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { RootState } from '../../../store'
import { fetchSmartFit } from '../../../store/slices/smartFit'

SwiperCore.use([Navigation])

const SmartFit: FC = () => {
  const { i18n, t } = useTranslation()
  const dispatch = useAppDispatch()
  const { smartFit } = useAppSelector((state: RootState) => state.smartFit)

  useEffect(() => {
    if (smartFit) return
    dispatch(fetchSmartFit())
  }, [])

  return (
    <div className="smart-fit">
      <div className="smart-fit__top">
        <Container>
          <div className="smart-fit__body">
            <div className="smart-fit__left">
              <BaseSvg
                className="smart-fit__svg d-none d-md-block"
                name="ico-smart-fit"
              />
              <h2 className="smart-fit__title">
                <span className="d-md-none">{smartFit?.title}</span>
                {smartFit?.sub_title}
              </h2>
              <p className="smart-fit__text">{smartFit?.description}</p>
              <ButtonArrowRight
                to={
                  i18n.language === 'ru'
                    ? '/reabilitacionnyj-centr'
                    : '/reabilitaciynyi-centr-saltivka'
                }
                className="smart-fit__more"
              >
                <span className="smart-fit__more-text">
                  {t('learn_more_2')}
                </span>
              </ButtonArrowRight>
            </div>
            <div className="smart-fit__right">
              <div className="smart-fit__right-content d-md-none">
                <div className="smart-fit__right-title">
                  {smartFit?.features}
                </div>
                {smartFit?.phone?.length
                  ? smartFit?.phone.map((el) => (
                      <a
                        key={el}
                        href={`tel:${el}`}
                        className="smart-fit__right-phone"
                      >
                        <BaseSvg name="ico-phone" />
                        {el}
                      </a>
                    ))
                  : null}

                <BaseSvg className="smart-fit__svg" name="ico-smart-fit" />
              </div>
              <img className="smart-fit__right-bg d-md-none" src={bg} alt="" />

              <picture className="smart-fit__right-bg d-none d-md-flex _mobile">
                <img src={bg_mobile} alt="" />
              </picture>

              <img className="smart-fit__right-girl" src={girl} alt="" />
            </div>
          </div>
        </Container>
      </div>
      {smartFit?.media?.length ? (
        <div className="smart-fit__bottom d-md-none">
          <Container>
            <Swiper
              className="smart-fit__slider"
              slidesPerView="auto"
              navigation={{
                nextEl: '.smart-fit__btn-next',
                prevEl: '.smart-fit__btn-prev',
              }}
            >
              {smartFit.media.map((item: IMedia) => (
                <SwiperSlide key={item.id} className="smart-fit__slide">
                  <img
                    className="smart-fit__slide-img"
                    src={item.preview_url}
                    alt="img"
                  />
                </SwiperSlide>
              ))}
              <ButtonSlider
                type="next"
                className="smart-fit__btn-prev smart-fit__btn"
              />
              <ButtonSlider
                type="prev"
                className="smart-fit__btn-next smart-fit__btn"
              />
            </Swiper>
          </Container>
        </div>
      ) : null}
    </div>
  )
}

export default memo(SmartFit)
